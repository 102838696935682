import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './Home.css';
import chatgptImg from '../img/chatgpt.png';
import ava1 from '../img/ava1.jpg';
import ava2 from '../img/ava2.jpg';
import ava3 from '../img/ava3.jpg';
import voicecloneImg from '../img/voiceclone.png';

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 }
};

const Home = () => {
  return (
    <div>
      <header className="hero">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <motion.h1
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                variants={sectionVariants}
                transition={{ duration: 1 }}
              >
                Добро пожаловать в будущее ИИ
              </motion.h1>
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                variants={sectionVariants}
                transition={{ duration: 1, delay: 0.5 }}
              >
                Откройте новые возможности с нашими ботами ChatGPT и VoiceClone
              </motion.p>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                variants={sectionVariants}
                transition={{ duration: 1, delay: 1 }}
              >
                <Button variant="primary" href="#projects">Узнать больше</Button>
                <Button variant="secondary" href="/info">О нас</Button>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </header>

      <motion.section
        id="projects"
        className="my-5"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        // variants={sectionVariants}
        transition={{ duration: 1 }}
      >
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <Card className="project-card">
                <Card.Img variant="top" src={chatgptImg} />
                <Card.Body>
                  <Card.Title>ChatGPT Bot</Card.Title>
                  <Card.Text>
                    🌟 <strong>Универсальный Бот-Помощник</strong> 🌟

                    <p>👋 Приветствуем! Наш бот создан для того, чтобы помочь вам с любыми вопросами и задачами, а также воплотить ваши идеи в яркие и качественные изображения!</p>

                    <h3>🔍 Что умеет наш бот?</h3>
                    <ul>
                      <li>Отвечать на любые ваши вопросы благодаря мощи <strong>ChatGPT-4</strong>.</li>
                      <li>Решать задачи любой сложности – будь то домашнее задание, профессиональный совет или личные вопросы.</li>
                      <li>Создавать потрясающие изображения по вашему описанию с использованием передовой технологии <strong>DALL-E 3</strong>.</li>
                    </ul>

                    <h3>🎨 Как это работает?</h3>
                    <p><strong>Текстовые вопросы и задачи:</strong> Просто напишите ваш вопрос или задачу, и бот предоставит вам точную и подробную информацию.</p>
                    <p><strong>Создание изображений:</strong> Опишите, что вы хотите увидеть, и бот нарисует для вас картинку в высоком качестве.</p>

                    <h3>🌈 Примеры использования:</h3>
                    <ul>
                      <li><strong>Образование:</strong> Помощь с домашними заданиями, подготовка к экзаменам, объяснение сложных тем.</li>
                      <li><strong>Работа:</strong> Подготовка презентаций, поиск информации, советы по проектам.</li>
                      <li><strong>Творчество:</strong> Создание уникальных картин, иллюстраций и дизайнов.</li>
                    </ul>

                    <p>Присоединяйтесь к нам и ощутите всю мощь современных технологий! Ваш идеальный помощник всегда рядом.</p>
                  </Card.Text>
                  <div className="project-buttons">
                    <Button variant="primary" href="https://t.me/newbotchatgpt_bot">Перейти в Telegram</Button>
                    <Button variant="primary" href="https://vk.com/newchatgpt">Перейти в VK</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={12}>
              <Card className="project-card">
                <Card.Img variant="top" src={voicecloneImg} />
                <Card.Body>
                  <Card.Title>VoiceClone Bot</Card.Title>
                  <Card.Text>
                    🌟 <strong>VoiceClone - Ваш голос, Ваш стиль!</strong> 🌟

                    <p>👋 Добро пожаловать! VoiceClone - это революционный проект, который позволяет озвучивать любой текст, используя как стандартные персонажи, так и загруженные вами исходные голоса.</p>

                    <h3>🔍 Что умеет VoiceClone?</h3>
                    <ul>
                      <li>Озвучивание текста голосами стандартных персонажей.</li>
                      <li>Использование вашего собственного голоса – просто отправьте голосовое сообщение, видеосообщение или видео, и мы создадим уникальный голосовой профиль.</li>
                      <li>Быстрая и качественная обработка аудиофайлов.</li>
                    </ul>

                    <h3>🎨 Как это работает?</h3>
                    <p><strong>Озвучивание стандартными голосами:</strong> Выберите из множества готовых голосов, и мы озвучим ваш текст в выбранном стиле.</p>
                    <p><strong>Использование собственного голоса:</strong> Отправьте нам голосовое или видеосообщение, и мы создадим его точную копию для озвучивания любых текстов.</p>

                    <h3>🌈 Примеры использования:</h3>
                    <ul>
                      <li><strong>Образование:</strong> Создание аудиоуроков, лекций и учебных материалов.</li>
                      <li><strong>Работа:</strong> Озвучивание презентаций, рекламных роликов и инструкций.</li>
                      <li><strong>Творчество:</strong> Создание аудиокниг, подкастов и анимационных видео.</li>
                    </ul>
                    <p>Присоединяйтесь к нам и создайте уникальные голосовые проекты с VoiceClone! 🚀</p>
                  </Card.Text>
                  <div className="project-buttons">
                    <Button variant="primary" href="https://t.me/CloneVoiceAi_bot">Перейти в Telegram</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </motion.section>

      {/* <motion.section
        id="demo"
        className="my-5 bg-light"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={sectionVariants}
        transition={{ duration: 1 }}
      >
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h2>Демонстрация работы</h2>
              <p>Примеры использования ChatGPT и VoiceClone.</p>
              <Button variant="primary" href="#test">Тестировать сейчас</Button>
            </Col>
          </Row>
        </Container>
      </motion.section> */}

      <motion.section
        id="reviews"
        className="my-5"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        variants={sectionVariants}
        transition={{ duration: 1 }}
      >
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h2>Отзывы пользователей</h2>
              <Row>
                <Col md={4}>
                  <Card>
                    <div className="d-flex justify-content-center">
                      <Card.Img
                        variant="top"
                        src={ava2}
                        style={{
                          borderRadius: '100px',
                          border: '3px solid green',
                          boxShadow: '0 0 7px #666',
                          width: '100px',
                          height: '100px',
                          alignSelf: 'center',
                        }}
                      />
                    </div>
                    <Card.Body>
                      <Card.Text>Этот бот просто потрясающий! Он помог мне разобраться с моими вопросами невероятно быстро и точно. Я был удивлён, насколько грамотно и полно он ответил на все мои запросы. Ещё мне очень понравилось, что бот может объяснить сложные вещи простым языком. Определённо рекомендую его всем, кто ищет надёжного и умного помощника!</Card.Text>
                      <footer className="blockquote-footer">Дмитрий Овчинников</footer>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={ava1}
                      style={{
                        borderRadius: '100px',
                        border: '3px solid green',
                        boxShadow: '0 0 7px #666',
                        width: '100px',
                        height: '100px',
                        alignSelf: 'center',
                      }}
                    />
                    <Card.Body>
                      <Card.Text>Я в восторге от работы этого бота! Он всегда на связи и готов помочь. Несколько раз он выручил меня, когда мне срочно нужна была информация или совет. Бот отвечает быстро и по делу, что очень экономит время. Особенно приятно, что его ответы всегда вежливые и доброжелательные. Отличный инструмент для решения повседневных задач!</Card.Text>
                      <footer className="blockquote-footer">Олеся Васильева</footer>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <div className="d-flex justify-content-center">
                      <Card.Img
                        variant="top"
                        src={ava3}
                        style={{
                          borderRadius: '100px',
                          border: '3px solid green',
                          boxShadow: '0 0 7px #666',
                          width: '100px',
                          height: '100px',
                          alignSelf: 'center',
                        }}
                      />
                    </div>
                    <Card.Body>
                      <Card.Text>Этот бот превзошёл все мои ожидания! Он не только отвечает на вопросы, но и предлагает дополнительные полезные сведения, о которых я даже не задумывался. Очень удобен в использовании, и его советы всегда актуальны и полезны. Я пользуюсь ботом уже несколько месяцев и каждый раз убеждаюсь, что это один из лучших помощников в интернете. Большое спасибо создателям за такой замечательный продукт!</Card.Text>
                      <footer className="blockquote-footer">Артем</footer>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </motion.section>
    </div>
  );
}

export default Home;
