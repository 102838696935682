import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './Payment.css';
import axios from 'axios';

const tg = window.Telegram.WebApp;

const Payment = () => {
  const [project, setProject] = useState('ChatGPT');
  const [projectId, setProjectId] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [id, setId] = useState(null);
  const [product, setProduct] = useState('premium1');
  const [currency, setCurrency] = useState('USD');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('yookassa');
  const [TG, setTG] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromURL = queryParams.get('id');
    const projectFromURL = queryParams.get('project');
    if (idFromURL) {
      setId(Number(idFromURL));
    }
    if (projectFromURL) {
      if(Number(projectFromURL) === 1) {
        setProject('ChatGPT');
        setProduct('premium1');
      } 
      if(Number(projectFromURL) === 2) {
        setProduct('voice1');
        setProject('CloneVoice');
      } 
      setProjectId(idFromURL);
    }
  }, []);

  const handleProjectChange = (val) => setProject(val);
  const handleСurrencyChange = (val) => setCurrency(val);
  const handleShowContacts = () => setShowContacts(true);
  const handleCloseContacts = () => setShowContacts(false);
  const handleShowDescription = () => setShowDescription(true);
  const handleCloseDescription = () => setShowDescription(false);

  async function selectButton() {
    setProject(project === 'ChatGPT' ? 'CloneVoice' : 'ChatGPT');
    setProduct(project === 'ChatGPT' ? 'voice1' : 'premium1');
  }
  
  function selectButtonCurrency(id) {
    setCurrency(id);
  }

  useEffect(() => {
    if(tg.initDataUnsafe.user) {
      setTG(true);
      setId(tg.initDataUnsafe?.user?.id);
    }
  }, []);

  const handleSubmit = async (e) => {
    if(id == null || product === false) return alert('Что-то не так');
    e.preventDefault();
    
    try {
      const response = await axios.post(`https://crystaldev.ru/${project === 'ChatGPT' ? `chatgpt` : `clonevoice`}`, {
        method: 'create',
        product: product,
        id: id,
        project: project,
        email: email,
        paymentMethod: paymentMethod,
        currency: currency
      });
      
      if(response.data && response.data.payment_url) {
        window.location.href = response.data.payment_url;
      } else {
        alert(response.data?.message ? response.data?.message :'Ошибка при создании платежа');
      }
    } catch (error) {
      console.error('Error during payment creation:', error);
      alert('Ошибка при создании платежа');
    }
  };

  return (
    <div className="payment-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="order-md-1">
            <Card className="payment-card">
              <Card.Body>
                <Card.Title className="text-center">Оплата</Card.Title>
                <Form onSubmit={handleSubmit}>
                  {projectId === false && (
                    <Form.Group className="mb-3">
                      <Form.Label>Выберите проект</Form.Label>
                      <ToggleButtonGroup
                        type="radio"
                        name="projects"
                        value={project}
                        onChange={handleProjectChange}
                        className="w-100"
                      >
                        <ToggleButton variant="outline-primary" value="ChatGPT" onClick={selectButton} className="me-2">ChatGPT</ToggleButton>
                        <ToggleButton variant="outline-primary" value="CloneVoice" onClick={selectButton}>CloneVoice</ToggleButton>
                      </ToggleButtonGroup>
                    </Form.Group>
                  )}
                  {TG === false && (
                    <Form.Group className="mb-3">
                      <Form.Label>Ссылка или ID</Form.Label>
                      <Form.Control type="number" min={0} placeholder="Введите ссылку или ID" defaultValue={id || ''} onChange={(e) => setId(e.target.value)} required />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Выберите товар</Form.Label>
                    <Form.Control as="select" value={product} onChange={(e) => setProduct(e.target.value)}>
                      {project === 'ChatGPT' ? (
                        <>
                          <option value="premium1">🔥Start 1 месяц - 250 RUB</option>
                          <option value="premium2">🔥Pro 1 месяц - 500 RUB</option>
                          <option value="premium4">🔥Годовой - 3.000 RUB</option>
                        </>
                      ) : (
                        <>
                          <option value="voice1">🔥20 Voice - 199₽</option>
                          <option value="voice2">🔥50 Voice - 359₽</option>
                          <option value="voice3">🔥100 Voice - 529₽</option>
                          <option value="voice4">🔥300 Voice - 1429₽</option>
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Выберите способ оплаты</Form.Label>
                    <Form.Control as="select" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                      <option value="yookassa">Yookassa - (РФ и СНГ) 0% комиссия</option>
                      <option value="crypto">Криптовалюта 0% комиссия</option>
                      <option value="worldpay">Другие страны 0% комиссия</option>
                      <option value="vkpay" disabled>VKPay - (РФ и СНГ) 0% комиссия</option>
                    </Form.Control>
                  </Form.Group>
                  {paymentMethod === `worldpay` && (
                    <Form.Group className="mb-3">
                      <ToggleButtonGroup
                        type="radio"
                        name="currency"
                        value={currency}
                        onChange={handleСurrencyChange}
                        className="w-100"
                      >
                        <ToggleButton variant="outline-primary" value="USD" onClick={() => selectButtonCurrency('USD')} className="me-2">USD</ToggleButton>
                        <ToggleButton variant="outline-primary" value="RUB" onClick={() => selectButtonCurrency('RUB')} className="me-2">RUB</ToggleButton>
                        <ToggleButton variant="outline-primary" value="EUR" onClick={() => selectButtonCurrency('EUR')}>EUR</ToggleButton>
                      </ToggleButtonGroup>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Электронная почта (чек)</Form.Label>
                    <Form.Control type="email" placeholder="Введите почту" defaultValue={email || ''} onChange={(e) => setEmail(e.target.value)} required />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">Оплатить</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="order-md-2 d-flex flex-column align-items-center justify-content-center mt-4 mt-md-0">
            <div className="button-group">
              <button className="custom-btn" onClick={handleShowDescription}>Описание товаров</button>
              <button className="custom-btn" onClick={handleShowContacts}>Контакты</button>
            </div>
            <Button className="custom-btn mt-3 user-agreement" href="/agreement.pdf" target="_blank">Пользовательское соглашение</Button>
          </Col>
        </Row>
      </Container>

      <Modal show={showContacts} onHide={handleCloseContacts}>
        <Modal.Header closeButton>
          <Modal.Title>Контакты</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='popup__inner-list'>
            <p className='popup__inner-main-title'>Связаться с нами</p>
            <p className='popup__inner-item'>Самозанятый Чертов Семён Сергеевич</p>
            <p className='popup__inner-item'>ИНН - 226702366647</p>
            <p className='popup__inner-item'>Почта: easybej1ukuu@gmail.com</p>
            <p className='popup__inner-item'>VK: https://vk.com/ez.bej1ukuu</p>
            <p className='popup__inner-item'>TG: https://t.me/bejikuu</p>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal show={showDescription} onHide={handleCloseDescription}>
        <Modal.Header closeButton>
          <Modal.Title>Описание товаров</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {project === 'ChatGPT' ? (
            <ul className='popup__inner-list'>
              <p className='popup__inner-main-title'>🔥Start</p>
              <p className='popup__inner-item'>1. 25.000 token/день.</p>
              <p className='popup__inner-item'>2. Возможность искать информацию по фото (допустим контрольная работа).</p>
              <p className='popup__inner-item'>3. Уменьшеный интервал между генерацией изображений Dalle 3.</p>
              <p className='popup__inner-item'>4. Настройка собственного стиля общения.</p>
              <p className='popup__inner-item'>4. Премиум чат, где мы сообщаем о новых обновлениях, которые можно использовать раньше всех.</p>
              <p className='popup__inner-item'>5. Безлимит на GPT-3.5-Turbo/Dall-e 2</p>
              <p className='popup__inner-main-title'>🔥Pro</p>
              <p className='popup__inner-item'>1. 50.000 token в сутки.</p>
              <p className='popup__inner-item'>2. ChatGPT 4</p>
              <p className='popup__inner-item'>3. Возможность искать информацию по фото (допустим контрольная работа) - ChatGPT Vision.</p>
              <p className='popup__inner-item'>4. Уменьшеный интервал между генерацией изображений Dalle 3.</p>
              <p className='popup__inner-item'>5. Выбор разрешения в генерации изображений Dalle 3.</p>
              <p className='popup__inner-item'>6. Настройка собственного стиля общения.</p>
              <p className='popup__inner-item'>7. Больше вариантов предложенных стилей.</p>
              <p className='popup__inner-item'>8. Премиум чат, где мы сообщаем о новых обновлениях, которые можно использовать раньше всех.</p>
              <p className='popup__inner-item'>9. Безлимит на GPT-3.5-Turbo/Dall-e 2</p>
              <p className='popup__inner-main-title'>🔥Годовой</p>
              <p className='popup__inner-item'>1. Всё из тарифа Pro с выгодой 50%</p>
            </ul>
          ) : (
            <ul className='popup__inner-list'>
              <p className='popup__inner-main-title'>🔥Voice</p>
              <p className='popup__inner-item'>Генерация без очереди.</p>
              <p className='popup__inner-item'>Ускоренная генерация.</p>
              <p className='popup__inner-item'>Отсутствие подписи к голосовому.</p>
              <p className='popup__inner-item'>Возможность загрузить собственных персонажей (3шт).</p>
              <p className='popup__inner-item'>Возможность клонирования голоса с видео/видеосообщения.</p>
              <p className='popup__inner-item'>Максимальный размер сообщения для озвучки – 700 символов</p>
              <p className='popup__inner-item'>Premium статус 10 дней.</p>
            </ul>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Payment;
