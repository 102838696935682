import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Info.css';

const Info = () => {
  return (
    <div className="info-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Card className="info-card">
              <Card.Body>
                <Card.Title className="text-center">О себе</Card.Title>
                <Row>
                  <Col md={4} className="d-flex justify-content-center align-items-center">
                    <div className="profile-photo">
                      <img src="myphoto.jpg" alt="Profile" className="img-fluid" />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="info-text">
                      <p><strong>Имя:</strong> Семён Чертов </p>
                      <p><strong>Email:</strong> easybej1ukuu@gmail.com</p>
                      <p><strong>Телефон:</strong> +7 (961) 231-2304</p>
                      <p><strong>О себе:</strong> Я веб-разработчик с более чем 3-летним опытом работы в создании интерактивных и динамичных веб-приложений. Я специализируюсь на современных технологиях, таких как React, Node.js и Express. Помимо этого, занимаюсь разработкой ботов для VK, Telegram и других социальных сетей, что позволяет мне создавать автоматизированные решения и улучшать взаимодействие пользователей с сервисами.</p>
                      <p><strong>Социальные сети: </strong> 
                        <a href="https://vk.com/ez.bej1ukuu" target="_blank" rel="noopener noreferrer">ВКонтакте,</a>
                        <a href="https://t.me/bejikuu" target="_blank" rel="noopener noreferrer">Telegram</a>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Info;
