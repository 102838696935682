import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Home from './components/Home';
import Payment from './components/Payment';
import Info from './components/Info';
import './App.css';
import logo from './img/logo.png'
import AOS from 'aos';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // продолжительность анимации в миллисекундах
      once: true, // анимация будет происходить только один раз
    });
  }, []);
  return (
    <Router>
      <div>
        <Navbar className="navbar-custom" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="Logo"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />
              {' '}
              Crystal Dev
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link as={Link} to="/">Главная</Nav.Link>
                <Nav.Link as={Link} to="/payment">Оплата</Nav.Link>
                <Nav.Link as={Link} to="/info">Информация</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/info" element={<Info />} />
        </Routes>
        <footer className="bg-dark text-white text-center py-3">
          <div>
            <p>Контакты: easybej1ukuu@gmail.com</p>
            <p>
              <a href="https://t.me/newbotchatgpt_bot" className="text-white">Telegram ChatGPT</a> |
              <a href="https://vk.com/newchatgpt" className="text-white"> VK ChatGPT</a> |
              <a href="https://t.me/CloneVoiceAi_bot" className="text-white"> Telegram VoiceClone</a>
            </p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
